<template>
  <v-main class="mt-3 container-prosker">
    <v-row>
      <v-col cols="12" md="3">
        <facet-options
          :update-results="updateResults"
          :url-params="globalSearch.categoryIds"
          :search-text="globalSearch.user"
        />
      </v-col>
      <v-col cols="12" md="9">
        <div v-if="loading">
          <v-skeleton-loader
            v-for="index in 5"
            :key="index"
            v-bind="skeletonAttrs"
            type="list-item-avatar, divider, list-item-three-line, card-heading, actions"
          ></v-skeleton-loader>
        </div>
        <v-card v-if="usersData.length">
          <v-card-text class="d-md-block d-none">
            <v-row
              class="border-bottom"
              v-for="(item, i) in usersData"
              :key="i"
            >
              <v-col cols="4" md="2" class="text-center">
                <v-avatar width="6rem" height="6rem">
                  <img
                    v-if="!item.profile_img"
                    src="@/assets/images/page-img/avatar.png"
                    alt="user"
                  />
                  <img
                    v-else
                    :src="item.profile_img"
                    class="rounded-circle"
                    alt="user"
                  />
                </v-avatar>
                <br />
                <v-rating
                  v-model="rating"
                  background-color="#59D79D"
                  color="#F2D022"
                  readonly
                  half-increments
                  dense
                  size="1rem"
                ></v-rating>
              </v-col>
              <v-col cols="8" md="10">
                <v-row class="pt-2">
                  <v-col cols="9">
                    <v-row>
                      <span
                        class="
                          pointer-cursor
                          mb-0
                          me-1
                          text-prosk
                          font-weight-bold
                        "
                        @click="goToUserProfile(item, true)"
                      >
                        {{ item.first_name }} {{ item.last_name }}
                      </span>
                    </v-row>
                    <v-row>
                      <span class="green-prosk">{{ item.count_works_done }} {{ $t('worksDone') }}</span>
                    </v-row>
                    <v-row>
                      <v-chip-group column>
                        <v-chip
                          v-for="(category, i) in item.category_name"
                          :key="i"
                          small
                          class="mx-2"
                          color="#59D79D"
                          text-color="white"
                        >
                          {{ category }}
                        </v-chip>
                      </v-chip-group>
                    </v-row>
                  </v-col>
                  <v-col cols="3">
                    <v-row class="float-end">
                      <v-btn
                        icon
                        @click="isFavorite(item.id, false)"
                        v-if="item.favorites.length !== 0"
                      >
                        <i class="ri-heart-fill ri-lg green-prosk"></i>
                      </v-btn>
                      <v-btn icon @click="isFavorite(item.id, true)" v-else>
                        <i class="ri-heart-line ri-lg green-prosk"></i>
                      </v-btn>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row class="pt-2">
                  <v-col cols="12" md="10">
                    <div class="pr-5">
                      <span>{{ item.description }}</span>
                    </div>
                  </v-col>
                  <v-col cols="12" md="2">
                    <div class="float-md-end mb-2 me-1">
                      <v-row>
                        <v-btn
                          class="bg-green-prosk mb-2 white--text"
                          small
                          @click="createConversation(item.id, item.username)"
                        >
                          <i class="ri-mail-line ri-1x"></i
                          ><span class="ms-1">{{ $t("message") }}</span>
                        </v-btn>
                      </v-row>
                      <v-row v-if="showScheduler">
                        <v-btn class="bg-green-prosk white--text" small>
                          <i class="ri-calendar-2-line ri-1x"></i
                          ><span class="ms-1">{{ $t("schedule") }}</span>
                        </v-btn>
                      </v-row>
                    </div>
                  </v-col>
                </v-row>
                <v-row class="mt-2 me-1 mb-3">
                  <v-chip-group column>
                    <v-chip
                        v-for="(skill, i) in item.skill_name"
                        :key="i"
                        small
                        outlined
                        class="ma-2 cursor-auto"
                        color="#59D79D"
                    >
                      {{ skill }}
                    </v-chip>
                  </v-chip-group>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text class="d-block d-md-none">
            <v-row
              class="border-bottom"
              v-for="(item, i) in this.usersData"
              :key="i"
            >
              <v-row>
                <v-col cols="3">
                  <v-avatar width="4.5rem" height="4.5rem">
                    <img
                      v-if="!item.profile_img"
                      src="@/assets/images/page-img/avatar.png"
                      alt="user"
                    />
                    <img
                      v-else
                      :src="item.profile_img"
                      class="rounded-circle"
                      alt="user"
                    />
                  </v-avatar>
                </v-col>
                <v-col cols="9">
                  <v-row>
                    <v-col cols="12" class="mt-5">
                      <v-row>
                        <v-col cols="6" class="p-0">
                          <span
                            class="mb-0 me-1 text-prosk font-weight-bold"
                            @click="goToUserProfile(item, true)"
                          >
                            {{ item.first_name }} {{ item.last_name }}
                          </span>
                        </v-col>
                        <v-col cols="6" class="p-0">
                          <v-btn
                            icon
                            @click="isFavorite(item.id, false)"
                            v-if="item.favorites.length !== 0"
                          >
                            <i class="ri-heart-fill ri-lg green-prosk"></i>
                          </v-btn>
                          <v-btn icon @click="isFavorite(item.id, true)" v-else>
                            <i class="ri-heart-line ri-lg green-prosk"></i>
                          </v-btn>
                          <v-btn icon v-show="!$vuetify.breakpoint.mobile">
                            <i class="ri-share-fill ri-lg green-prosk"></i>
                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-row class="p-0">
                        <v-rating
                          v-model="rating"
                          background-color="#59D79D"
                          color="#F2D022"
                          half-increments
                          readonly
                          dense
                          size="1rem"
                        ></v-rating>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row class="mb-2">
                <v-col cols="12">
                  <span class="green-prosk">{{ item.count_works_done }} {{ $t('worksDone') }}</span>
                </v-col>
                <v-col cols="12">
                  <v-chip-group column>
                    <v-chip
                      v-for="(category, i) in item.category_name"
                      :key="i"
                      small
                      class="mx-2"
                      color="#59D79D"
                      text-color="white"
                    >
                      {{ category }}
                    </v-chip>
                  </v-chip-group>
                </v-col>
                <v-col cols="12" class="px-4">
                  <v-row>
                    <span class="px-2">{{ item.description }}</span>
                  </v-row>
                  <v-row>
                    <v-chip-group column>
                      <v-chip
                        v-for="(skill, i) in item.skill_name"
                        :key="i"
                        small
                        class="ma-2"
                        color="#59D79D"
                        outlined
                      >
                        {{ skill }}
                      </v-chip>
                    </v-chip-group>
                  </v-row>
                  <v-row>
                    <v-col cols="6">
                      <v-btn
                        class="bg-green-prosk white--text"
                        small
                        @click="createConversation(item.id, item.username)"
                      >
                        <i class="ri-mail-line ri-1x"></i
                        ><span class="ms-1">{{ $t("message") }}</span>
                      </v-btn>
                    </v-col>
                    <v-col cols="6" v-if="showScheduler">
                      <v-btn class="bg-green-prosk white--text" small>
                        <i class="ri-calendar-2-line ri-1x"></i
                        ><span class="ms-1">{{ $t("schedule") }}</span>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-row>
          </v-card-text>
          <div class="text-center" v-if="lastPage !== 1">
            <v-pagination
              v-model="page"
              :length="lastPage"
              color="#59D79D"
              circle
            ></v-pagination>
          </div>
        </v-card>
        <alert :message="$t('noResults')" v-else/>
      </v-col>
    </v-row>
  </v-main>
</template>
<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import FacetOptions from '@/components/socialvue/filters/FacetOptions.vue';
import errorDialog from '@/components/socialvue/mixins/errorDialog';
import Alert from '@/components/socialvue/alerts/Alert.vue';
import goToUserProfile from '@/components/socialvue/mixins/goToUserProfile';
export default {
  name: 'UserFavorites',
  components: { Alert, FacetOptions },
  mixins: [errorDialog, goToUserProfile],
  data () {
    return {
      page: 1,
      lastPage: 1,
      facetOptionsSelected: [],
      skeletonAttrs: {
        class: 'mb-6',
        boilerplate: true,
        elevation: 2
      },
      loading: true,
      showScheduler: false,
      globalSearch: {},
      rating: 5,
      usersData: []
    };
  },
  created () {
    window.scroll({
      top: 0,
      behavior: 'smooth'
    });
    this.getUsersFavorites();
    this.globalSearch = {};
    this.globalSearch = JSON.parse(this.$route.params.query);
    if (this.globalSearch.categoryIds && this.globalSearch.categoryIds.length) {
      this.globalSearch.categoryIds.forEach((categoryId) => {
        this.facetOptionsSelected.push({
          field_name: 'categories.id',
          value: categoryId,
          id: null
        });
      });
    }
    this.showCalendar();
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser'
    })
  },
  watch: {
    facetOptionsSelected: function (value) {
      if (value) {
        this.page = 1;
      }
    },
    page: function (value) {
      if (value) {
        this.getUsersFavorites();
      }
    },
    $route (to) {
      if (to.params.query !== this.globalSearch) {
        this.globalSearch = JSON.parse(to.params.query);
        this.getUsersFavorites();
      }
    }
  },
  methods: {
    showCalendar () {
      if (this.currentUser) {
        this.currentUser.active_subscription.forEach((subscription) => {
          this.showScheduler = subscription.cost !== 0;
        });
      }
    },
    updateResults (data) {
      this.facetOptionsSelected = data;
      this.getUsersFavorites();
    },
    isFavorite (userId, bool) {
      const url = `${process.env.VUE_APP_BASE_URL}/api/favorites`;
      if (bool) {
        const data = {
          favorite_user_id: userId,
          user_id: this.currentUser.id
        };
        axios
          .post(url, data)
          .then(() => {
            this.getUsersFavorites();
          })
          .catch((error) => {
            this.showError(error);
          });
      } else {
        axios
          .delete(url, {
            data: {
              favorite_user_id: userId
            }
          })
          .then(() => {
            this.getUsersFavorites();
          })
          .catch((error) => {
            this.showError(error);
          });
      }
    },
    getConversation (recipientId, senderId, callback) {
      const metadata = [recipientId, senderId].sort((a, b) => {
        if (a >= b) {
          return 1;
        }
        return -1;
      }).join(',');
      // TODO - improve conversation filtering!
      const url = `${process.env.VUE_APP_BASE_URL}/api/conversations?search=${metadata}`;
      axios
        .get(url)
        .then((response) => {
          if (!response.data.data.length) {
            throw new Error(this.$t('errorLoadingConversation'));
          } else {
            callback(response.data.data[0].id);
          }
        })
        .catch((error) => {
          this.showError(error);
        });
    },
    createConversation (userId, userName) {
      if (!this.currentUser) {
        this.$swal.fire({ title: this.$t('accessContentNeedLogIn') });
        this.$router.push({ name: 'auth1.sign-in1' });
      } else {
        const url = `${process.env.VUE_APP_BASE_URL}/api/conversations`;
        this.name = userName;
        this.userIds = [userId, this.currentUser.id];
        const data = {
          name: this.name,
          user_ids: this.userIds
        };
        axios
          .post(url, data)
          .then((res) => {
            this.$router.push({
              name: 'app.chat',
              params: { conversationId: res.data.data[0].id.toString() }
            });
          })
          .catch((error) => {
            if (error.response.data.errors.user_ids && error.response.data.errors.user_ids[0] === 'conversationAlreadyCreated') {
              this.getConversation(
                userId,
                this.currentUser.id,
                (conversationId) => {
                  this.$router.push({
                    name: 'app.chat',
                    params: { conversationId: conversationId.toString() }
                  });
                }
              )
            } else {
              this.showError(error);
            }
          });
      }
    },
    getURL () {
      if (!this.currentUser) {
        return;
      }

      const urlFilters = {};
      let filterQuery = '';
      this.facetOptionsSelected.forEach((facetOption) => {
        if (urlFilters[facetOption.field_name]) {
          urlFilters[facetOption.field_name].push(facetOption.value);
        } else {
          urlFilters[facetOption.field_name] = [facetOption.value];
        }
      });
      if (JSON.stringify(urlFilters) !== '{}') {
        for (let prop in urlFilters) {
          filterQuery += `${prop}=${urlFilters[prop].join('|')}|,`;
        }
        filterQuery += `profile_img=notNull`;
      }
      if (this.facetOptionsSelected.length !== 0) {
        return `${process.env.VUE_APP_BASE_URL}/api/users?filters=favorites.user_id=${this.currentUser.id}|,${filterQuery}&page=${this.page}`;
      } else {
        return `${process.env.VUE_APP_BASE_URL}/api/users?filters=favorites.user_id=${this.currentUser.id}|&page=${this.page}`;
      }
    },
    getUsersFavorites () {
      this.usersData = [];
      this.loading = true;
      axios
        .get(this.getURL())
        .then((response) => {
          response.data.data.forEach((user) => {
            this.usersData.push(user);
          });
          this.lastPage = response.data.last_page;
          this.usersData = this.usersData.filter(
            (user) => user.id !== this.currentUser.id
          );
          this.loading = false;
        })
        .catch((error) => {
          this.showError(error);
          this.loading = false;
        });
    }
  }
};
</script>
